<template>
  <div class="homepage-5 the-search hd-white">
    <div id="wrapper">
     <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />

    <div class="container mt-5 res-show-mobile">
          <h6 class="text-center" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">VENCASA EXPLAINED</h6>
          <h2 class="text-center" style="font-family: 'Montserrat', sans-serif;
    font-weight: 600;">How it Works</h2>
  <div class="row mt-5">
    
    <div class="col-sm text-center ">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;font-size: 1.5rem;
                    "
                    class="mb-5"
                    >List your property</strong>

                     <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
                    Tell us about your property/ take pictures, write a description and create a floor plan.
                  </p>

    </div>
    <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Make payment</strong>

                     <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
                     One simple payment, no hidden fees or percentage commissions. We also plant a tree on your behalf.
                  </p>

    </div>
    <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Show off your property</strong>

                     <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
                    Share your new listing with friends on social media. Message buyers directly and arrange viewings.
                  </p>

    </div>
      <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Accept an Offer</strong>

                     <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
                   Accept an offer you are happy with and your solicitors will handle all the legal side of things.
                  </p>

    </div>
     <div class="col-sm text-center">
     <strong
                    style="
                      text-transform: initial;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600; font-size: 1.5rem;
                    "
                    class="mb-5"
                    >Relax</strong>

                     <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
                   Congratulations, now sit back and ponder the ways to treat yourself with your saved commission.
                  </p>

    </div>
  </div>
</div>
     
      <section
        style="margin-top: 80px; margin-left: 25px; margin-right: 25px"
        _ngcontent-bgi-c3=""
        class="featured-boxes-area res-s-dnone"
      >
       
        <div class="title container mt-5">
          <h6 class="text-center" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">VENCASA EXPLAINED</h6>
          <h2 class="text-center res-font-30" style="font-family: 'Montserrat', sans-serif;
    font-weight: 700; font-size:45px;">How it Works</h2>
        </div>
 
        
          <div style="    margin: 2em auto; width:80%" class="row">
            <ul style="margin-left: -60px;
    margin-right: -60px;" class="progress-steps">
              <li class="active">
                <strong style="text-transform: initial; font-family: 'Montserrat', sans-serif;
    font-weight: 600;" class="mb-5"
                  >List your property</strong
                >
                <p
                  style="
                    text-transform: initial;
    margin-left: 2px;
    margin-right: 4px;
    padding-right: 35px;
    font-size: 1rem;
    padding-left: 35px;
    font-family: Montserrat, sans-serif;

                  "
                  class="mt-5 "
                >
                  Tell us about your property/ take pictures, write a
                  description and create a floor plan.
                </p>
              </li>

              <li>
                <strong style="text-transform: initial; font-family: 'Montserrat', sans-serif;
    font-weight: 600;" class="mb-5"
                  >Make payment</strong
                >
                <p
                  style="
                    text-transform: initial;
    margin-left: 2px;
    margin-right: 4px;
    padding-right: 35px;
    font-size: 1rem;
    padding-left: 35px;
    font-family: Montserrat, sans-serif;
   
                  "
                  class="mt-5 "
                >
                  One simple payment, no hidden fees or percentage commissions.
                  We also plant a tree on your behalf.
                </p>
              </li>

              <li>
                <strong style="text-transform: initial; font-family: 'Montserrat', sans-serif;
    font-weight: 600;" class="mb-5"
                  >Show off your property</strong
                >
                <p
                  style="
                    text-transform: initial;
    margin-left: 2px;
    margin-right: 4px;
    padding-right: 35px;
    font-size: 1rem;
    padding-left: 35px;
    font-family: Montserrat, sans-serif;
    
                  "
                  class="mt-5 "
                >
                  Share your new listing with friends on social media. Message
                  buyers directly and arrange viewings.
                </p>
              </li>

              <li>
                <strong style="text-transform: initial; font-family: 'Montserrat', sans-serif;
    font-weight: 600;" class="mb-5"
                  >Accept an Offer</strong
                >
                <p
                  style="
                    text-transform: initial;
    margin-left: 2px;
    margin-right: 4px;
    padding-right: 35px;
    font-size: 1rem;
    padding-left: 35px;
    font-family: Montserrat, sans-serif;
                  "
                  class="mt-5 "
                >
                  Accept an offer you are happy with and your solicitors will
                  handle all the legal side of things.
                </p>
              </li>

              <li>
                <strong style="text-transform: initial; font-family: 'Montserrat', sans-serif;
    font-weight: 600;" class="mb-5"
                  >Relax</strong
                >
                <p
                  style="
                    text-transform: initial;
    margin-left: 2px;
    margin-right: 4px;
    padding-right: 35px;
    font-size: 1rem;
    padding-left: 35px;
    font-family: Montserrat, sans-serif;
                  "
                  class="mt-5 "
                >
                  Congratulations, now sit back and ponder the ways to treat
                  yourself with your saved commission.
                </p>
              </li>
            </ul>
          </div>
       
      </section>

      <section
        class="blog-section bg-white"
        :style="`background-image : url(${require('../assets/images/bg/big.webp')}) !important; background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    padding: 0px;
    background-repeat: no-repeat !important;`"
      >
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-auto mt-5">
              <h6
                class="text-uppercase text-center mb-4"
                style="font-family: 'Montserrat', sans-serif;
    font-weight: 600;"
              >
                Our Pricing
              </h6>
              <h3 class="text-center  mb-5" style="font-size: 30px; font-family: 'Montserrat', sans-serif;
    ">
                One Plan, Fixed Pricing
              </h3>
              <div
                class="text-center"
                style="
                  border: 3px solid;
                  margin-bottom: 20px;
                  border-color: rgb(84, 143, 77);
                  border-radius: 8px;
                "
              >
                <div class="p-3">
                  <h5 class="mb-3" style="font-weight: 700; font-size: 25px;     font-family: 'Montserrat', sans-serif;
">
                    The One
                  </h5>
                  <h5
                    class="mb-3"
                    style="font-size: 45px; color: #548f4d; font-weight: 700;     font-family: 'Montserrat', sans-serif;"
                  >
                    £250
                  </h5>
                  <h6 class="mb-5" style="font-weight: bold;     font-family: 'Montserrat', sans-serif;">
                    WHAT'S INCLUDED
                  </h6>
                  <p class="list-des m30" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">
                    Direct Contact with Potential Buyer
                  </p>
                  <p class="list-des m30" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">List Until Sold</p>
                  <p class="list-des m30" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">Offers Sent Instantly</p>
                  <p class="list-des m30" style="    font-family: 'Montserrat', sans-serif;
    font-weight: 600;">
                    Unlimited viewings at Times to Suit You
                  </p>
                  <p class="list-des m30" style="margin-bottom: 30px; font-family: 'Montserrat', sans-serif;
    font-weight: 600;">
                    You Keep Full Control
                  </p>
                  <button
                    style="
                      background-color: rgb(84, 143, 77);
                      color: white;
                      padding: 20px 60px;
                      border-radius: 8px;
                      border: 0px;
                     
                      margin-bottom: 10px;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    List Your Property
                  </button>
                </div>
              </div>
              <div class="text-center">
                <span style="font-family: 'Montserrat', sans-serif;"> Are you a property developer? </span>
                <a style="font-family: 'Montserrat', sans-serif;" href="#"> Contact us </a>
              </div>
              <br />
              <button
                class="text-center text-uppercase mt-4 mb-5"
                style="
                  background-color: rgb(84, 143, 77);
                  color: white;
                  width: 100%;
                  padding: 8px 60px;
                  border-radius: 41px;
                
                  border: 0px;
                  font-family: 'Montserrat', sans-serif;
                "
              >
                plant a tree with everything
              </button>
            </div>
          </div>
          <div class="sec-title">
            <h2>A Community of smart buyers and sellers</h2>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <div style="margin-top: 25%; margin-bottom: 25%">
                <h2>Save Money, No Estate Agent's Fees</h2>
                <p style="line-height: 30px; font-family: 'Montserrat', sans-serif;">
                  Estate agent’s fees add thousands of pounds to the cost of
                  selling a home. Estate agents will usually charge a percentage
                  fee, which can range from 1% to 5% of the agreed selling
                  price.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <img class="img-fluid" src="../assets/images/bg/image1.webp" />
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-6 res-big-display">
              <div style="margin-top: 15%; margin-bottom: 15%">
                <h2>Connecting Buyers & Sellers Directly</h2>
                <p style="line-height: 30px; font-family: 'Montserrat', sans-serif;">
                  SELLING with Vencasa means you keep full control and speak
                  with buyers directly. You can arrange your own viewings and
                  negotiate on price, all within Vencasa’s safe messaging
                  service.
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <img class="img-fluid" src="../assets/images/bg/image2.webp" />
            </div>
            <div class="col-md-6 res-display">
              <div style="margin-top: 25%; margin-bottom: 25%">
                <h2>
                  Connecting Buyers & Sellers <br />
                  Directly
                </h2>
                <p style="line-height: 30px">
                  SELLING with Vencasa means you keep full control and speak
                  with buyers directly. You can arrange your own viewings and
                  negotiate on price, all within Vencasa’s safe messaging
                  service.
                </p>
              </div>
            </div>
          </div>
          <div class="sec-title">
            <h2><span>Articles &amp; </span>Tips</h2>
            <p style="font-family: 'Montserrat', sans-serif;">Read the latest news from our blog.</p>
          </div>
          <div class="news-wrap mb-5">
            <div class="row">
              <articles
                v-for="blog in blogs ? blogs.slice(0, 3) : []"
                :key="blog.id"
                :blog="blog"
              />
            </div>
          </div>
        </div>
      </section>
      <a data-scroll href="#wrapper" class="go-up" style="display: inline"
        ><i class="fa fa-angle-double-up" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const articles = () => import("../components/articles");

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      blogs: "getBlogs",
      authUser: "auth",
    }),
  },
  components: {
    articles,
  },
  methods: {
  },
  async mounted() {
    if (!this.blogs) {
      this.$store.dispatch("blogs", { page: 1, perPage: 10 });
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }
  .res-show-mobile{
    display: none !important;
  }
}

@media (max-width: 700px) {
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-s-dnone {
    display: none;
  }

  .res-font-30{
    font-size: 30px !important;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }
  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    margin:0px !important;
    width: 250px !important;
    padding: 13px !important;
    
  }
  .res-button-prop {
    padding: 13px 49px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 802px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}
/* @media screen and (max-device-width:640px), screen and (max-width:640px) {
.res-p{
  padding-top: 464px;
}
.res-bimg{
      background-size: 100% 268px !important;
      height: 795px !important;
}
.res_remove_ml{
    margin-left:0px !important;
}
} */

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
  /* flex-wrap: wrap; */
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
/* .advanceSlow {
  padding: 12px 4px 12px 10px !important;
  margin: 0 15px 0 15px !important;
} */
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
    height: 4.5em;
    content: counter(step);
    counter-increment: step;
    line-height: 4em;
    border: 6px solid green;
    display: block;
    text-align: center;
    margin: 10px auto 0.7em auto;
    border-radius: 50%;
    background-color: white;
    padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
/* .progress-steps li.active {
    color: $brand-color;
  }
  .progress-steps li.active:before {
    border-color: $brand-color;
  }
  .progress-steps li.active + li:after {
    background-color: $brand-color;
  } */
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}
</style>

